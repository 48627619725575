import React from "react";
import "./Button.scss";
import customLogEvent from "../../firebase-analytics";


export default function Button({text, className, href, newTab}) {
  if(text === "See my resume") {
    customLogEvent('Resume');
  }
  return (
    <div className={className}>
      <a className="main-button" href={href} target={newTab && "_blank"}>
        {text}
      </a>
    </div>
  );
}
