import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCqwq8E_WdWy2B2TTCPibIKh5n7sAaTNq4",
    authDomain: "tirthb-portfolio.firebaseapp.com",
    projectId: "tirthb-portfolio",
    storageBucket: "tirthb-portfolio.firebasestorage.app",
    messagingSenderId: "754188033025",
    appId: "1:754188033025:web:610d36af7d49b13f3af400",
    measurementId: "G-RPL6CRR2BZ"
  };
  
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const customLogEvent = (event) => logEvent(analytics, event);

export default customLogEvent;